.page-container {
  padding-left: 10vw;
  padding-right: 10vw;
  width: calc(100% - 20vw);
  color: #4D4D4D;
}

.container-content {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.hero-big,
.hero-small {
  position: relative;
  min-height: 600px;
  max-height: 650px;
  background: linear-gradient(90deg, #2D619A 0%, #3EBCF7 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5vw;
  padding-right: 5vw;
}

.hero-small {
  min-height: 350px;
}

.chat-hero {
  z-index: -1;
  min-height: 400px;
  height: 40dvh;
}

.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  color: white;
}

.hero-content .text-container {
  max-width: 50%;
}

.hero-orange-v4 {
  position: absolute;
  top: 137px;
  left: 0;
  right: 300px;
  margin-left: auto;
  margin-right: auto;
  z-index: 0;
}

.hero-orange-stick {
  position: absolute;
  top: 143px;
  left: 0;
  right: 0;
  margin-right: auto;
  width: 50%;
  height: 14px;
  background: linear-gradient(90deg, #F08B3C 0%, #E55137 100%);
}

.responsive-auth {
  display: none;
}

.hero-text {
  font-size: 2em;
  margin-bottom: 20px;
  line-height: 1.3;
  font-weight: 400;
}

.chat-hero .hero-text {
  margin-bottom: 150px;
}

.hero-content .text-container p {
  font-size: 1.3em;
  line-height: 1.5;
  /* font-weight: 400; */
}

.hero-divider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 0;
  margin-bottom: -1px;
}

.hero-divider svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 103px;
}

.hero-divider .shape-fill {
  fill: #FFFFFF;
}

.features-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 140px auto 0 auto;
  padding: 20px 0;
  padding-inline-start: 0;
  max-width: 1000px;
}

.features-list li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  transition: color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.features-list a {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0;
  color: #4D4D4D;
}

.features-list li:hover,
.features-list a:hover,
.features-list li:hover svg,
.features-list a:hover svg {
  color: #FF9700 !important;
  transform: scale(1.1);
}

.content-section {
  display: flex;
  gap: 20px;
  margin-top: 70px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.content-section img {
  width: 400px;
  height: auto;
  max-width: 50%;
  margin-left: 36px;
  margin-right: 36px;
}

.content-section .image-second {
  display: none;
}

.content-section-text {
  max-width: 50%;
}

.content-section-text h1 {
  color: #177EC0;
  margin-bottom: 8px;
}

.content-section-text p {
  margin: 0;
}

.page-container p {
  font-size: 1.1em;
  line-height: 1.5;
}

.features-section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 80px;
}

.features-section div {
  width: calc(33.33%);
  text-align: center;
}

.features-section div .icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
}

.features-section .icon-title {
  color: #E34435;
  text-align: center;
  font-weight: 600;
}

.features-section .description {
  max-width: 80%;
  margin: 10px auto;
  text-align: left;
  line-height: 1.5;
  font-size: 1.1em;
}

.hp-animation div {
  max-width: 100vw;
}

.hp-animation svg {
  max-width: 100vw;
}

@media (max-width: 1300px) {
  .hero-orange-v4 {
    right: 35vw;
  }

  .hero-orange-stick {
    background: linear-gradient(90deg, #F08B3C 0%, #E55137 50%);
  }
}

@media (max-width: 1100px) {
  .hero-orange-v4 {
    right: 38vw;
  }

  .hero-orange-stick {
    background: linear-gradient(90deg, #F08B3C 0%, #E55137 40%);
    width: 30%;
  }
}

@media (max-width: 1020px) {
  .hero-orange-v4 {
    position: relative;
    margin-right: 0;
    top: 20px;
    scale: 0.9;
  }

  .hero-orange-stick {
    position: relative;
    width: 100%;
    margin-left: -85%;
    height: 13px;
    margin-right: 0;
    right: 0;
    top: 63px;
    background: linear-gradient(90deg, #F08B3C 0%, #E55137 140%);
  }

  .orange-swirl {
    position: absolute;
    top: 50px;
    right: 0;
    margin-right: 0;
  }

  .page-container {
    padding-left: 5vw;
    padding-right: 5vw;
    width: calc(100% - 10vw);
  }

  .hero-big {
    align-items: flex-start;
  }

  .hero-content {
    display: block;
  }

  .hero-content .auth-container {
    display: none;
  }

  .responsive-auth {
    display: block;
  }

  .hero-big .text-container {
    max-width: 650px;
    margin-bottom: 100px;
    margin-top: 200px;
  }

  .features-list {
    margin-top: 64px;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 0px;
  }

  .features-list li {
    margin-bottom: 64px;
    flex: 0 0 calc(50% - 20px);
    justify-content: center;
  }

  .content-section {
    margin-top: 18px;
  }

  .features-section {
    flex-wrap: wrap;
  }

  .features-section div {
    flex: 0 0 calc(50% - 10px);
    margin-bottom: 32px;
  }

  .features-section div:nth-child(2n+1):last-child {
    margin: 0 auto;
  }
}

@media (max-width: 900px) {
  .content-section {
    display: block;
  }

  .content-section img {
    display: block;
    margin: 20px auto 0 auto;
    max-width: 100%;
    width: auto;
  }

  .content-section-text {
    max-width: 100%;
    width: 100%;
  }

  .content-section .image-first {
    display: none;
  }

  .content-section .image-second {
    display: block;
  }

  .features-section {
    margin-top: 64px;
    margin-bottom: 64px;
  }
}

@media (max-width: 750px) {
  .orange-swirl {
    right: -2vw;
  }
}

@media (max-width: 700px) {
  .hero-big {
    min-height: 500px;
    padding-bottom: 64px;
  }

  .content-section-text {
    margin-top: 0;
  }

  .orange-swirl {
    top: 30px;
  }

  .hero-big .text-container {
    margin-top: 160px;
    max-width: 80vw;
    margin-left: -5vw;
  }
}

@media (max-width: 600px) {
  .features-list {
    display: block;
  }

  .features-section div {
    flex: 0 0 calc(100% - 20px);
  }
}

@media (max-width: 570px) {
  .orange-swirl {
    display: none;
  }

  .hero-content .text-container {
    margin-top: calc(60px + 5vh);
    margin-bottom: 10vh;
  }

  .hero-big {
    min-height: 400px;
    padding-bottom: 44px;
  }

  .hero-text span {
    /* textcolor orange gradient */
    background: linear-gradient(180deg, #F08B3C 0%, #E55137 100%);
    -webkit-background-clip: text;
    background-clip: text; /* Standard property */
    -webkit-text-fill-color: transparent;
    font-weight: 800;
  }
}

@media (max-width: 508px) {
  .hero-content .text-container {
    font-size: 0.9em;
  }

  .hero-big {
    padding-bottom: 0px;
  }

  .hero-divider {
    transform: scaleY(-0.5);
    margin-bottom: -30px;
  }
}

@media (max-width: 450px) {
  .hero-content .text-container {
    margin-top: calc(60px + 5vh);
    margin-bottom: 10vh;
  }

  .hp-animation div {
    margin-left: -5vw !important;
    transform: scale(0.8)
  }
}