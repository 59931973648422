/* General styles for the section */
.action-page {
  background: url('../../assets/chat-background.jpg') repeat;
  background-size: 300px !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

.action-card {
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.action-card form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.action-card .logo {
  max-width: 200px;
}

.action-card header {
  align-self: flex-start;
}

/* Styling for radio group container */
.action-radio-group {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
}

/* Input field styling */
.action-card section {
  max-width: calc(100% - 32px) !important;
  margin-bottom: 32px;
}

/* Buttons container */
.action-buttons {
  display: flex;
  gap: 1rem;
}

/* Button styles */
.action-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: #0056b3;
}

.action-button.inverted {
  background-color: transparent;
  border: 1px solid #0056b3;
  color: #0056b3;
}

.action-button.inverted:hover {
  background-color: #0056b3;
  color: #fff;
}