.login-register-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  overflow: hidden;
  z-index: 0;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gradient-overlay {
  position: absolute;
  left: 0;
  width: 100%;
  height: 20vh;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  bottom: 0;
}

.login-ad-logo {
  width: 160px;
  bottom: 0;
  z-index: 99;
  padding: 20px;
}

.login-register-page .auth-container {
  margin-left: auto;
  margin-right: auto;
  float: none;
}

.login-register-page .auth-card {
  background-color: white;
  border-radius: 8px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding-bottom: 0;
}

.auth-logo-container {
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  width: 100%;
}

.auth-logo-text {
  right: 0;
  color: black;
  font-weight: 500;
  padding: 20px;
}

@media (max-width: 1020px) {
  .login-register-page {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .auth-logo-text {
    margin-right: 5vw;
  }

  .login-register-page .auth-container {
    margin-top: 20vh;
  }
}

@media (max-width: 570px) {
  .auth-logo-text {
    display: none;
  }
}
