.profile-link-container {
  display: flex;
  align-items: center;
}

/* .profile-link-container svg {
  margin-right: 8px;
} */

.profile-buttons {
  width: auto;
  float: right;
  display: flex;
  position: absolute;
  right: 20px;
  top: 215px;
}

.profile-button {
  color: rgb(196, 196, 196);
  font-size: 16px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  text-transform: none;
}

.profile-close-button {
  font-size: 16px;
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(21, 21, 21, 0.2);
}

.profile-card-section {
  width: 320px;
}

.profile-width {
  width: calc(100% - 25px) !important;
}

.profile-card-container {
  text-align: left;
  margin-left: 24px;
  margin-top: 24px;
  margin-bottom: 100px;
}

.profile-card-container .profile-text {
  max-width: 280px !important;
  height: auto;
  display: inline-block;
  /* Ensure the span respects the width constraint */
  word-wrap: break-word;
  /* Break words that are too long */
  overflow-wrap: break-word;
  /* Ensure compatibility with different browsers */
}

.profile-card-header {
  display: flex;
  align-items: center;
}

.profile-card-icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-card-title {
  margin-left: 8px;
}

.profile-detail {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.profile-detail-key {
  width: 100px;
  text-align: left;
}

.profile-detail-value {
  flex: 1;
  text-align: left;
  color: rgb(117, 117, 117);
  margin-left: 8px;
}

.user-profile-section {
  height: calc(100dvh - 56px);
  overflow-y: auto;
  background-color: rgb(246, 246, 246);
}

.profile-text {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

.profile-flex {
  display: flex;
}

.profile-friends,
.profile-gallery {
  margin-left: 8px;
}

.profile-friends>section>section {
  max-height: 50vh;
  overflow-y: auto;
}

.profile-gallery>section {
  width: 30vw;
  min-width: 600px;
  max-width: 1000px;
}

.profile-gallery {
  column-count: 3;
  column-gap: 8px;
}

.profile-gallery img {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 8px;
  break-inside: avoid;
}

.profile-gallery img:hover {
  cursor: pointer;
  opacity: 0.8;
}

@media (max-width: 1560px) {
  .profile-gallery-container {
    flex-direction: column;
  }

  .profile-gallery-container .profile-card-container {
    margin-bottom: 0;
  }

  .profile-gallery-container .profile-friends, .profile-gallery {
    margin-top: 0;
    margin-left: 24px;
  }

  .profile-gallery {
    column-count: 1;
  }

  .profile-gallery>section {
    min-width: 320px;
  }
}

@media (max-width: 1200px) {
  .profile-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  
  .profile-buttons > button,
  .profile-buttons > div {
    flex: 0 0 calc(50% - 8px); /* Take up half the space minus margin */
    margin: 4px;
  }
  
  /* Target the third button specifically to ensure it aligns right */
  .profile-buttons > button:nth-child(3),
  .profile-buttons > div:nth-child(3) {
    margin-left: auto;
  }

  /* Cover image */
  .user-profile-section>section:first-child {
    height: 380px !important;
  }
}

@media (max-width: 950px) {
  .profile-flex {
    flex-direction: column;
  }

  .profile-card-container {
    margin-bottom: 0;
  }

  .profile-friends, .profile-gallery {
    margin-top: 0;
    margin-left: 24px;
  }
}

@media (max-width: 768px) {
  .profile-width {
    margin-top: 100px;
  }

  /* Cover image */
  .user-profile-section>section:first-child>section:first-child {
    height: 120px !important;
  }

  /* Profile avatar */
  .user-profile-section>section:first-child>section:nth-child(2) {
    height: 100px !important;
    width: 100px !important;
    top: 120px !important;
    left: 66px !important;
  }

  /* Header text */
  .user-profile-section>section:first-child>h1 {
    top: 120px !important;
    left: 128px !important;
  }

  .profile-buttons {
    position: absolute;
    top: 215px;
    right: 0;
    left: 0; /* Make it stretch full width */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the buttons */
    background-color: white; /* Add white background */
    padding: 10px 0; /* Add some padding */
  }

  /* First two buttons side by side and centered */
  .profile-buttons > button,
  .profile-buttons > div {
    margin: 4px;
    flex: 0 0 calc(50% - 16px); /* Slightly smaller to ensure they fit */
    display: flex;
    justify-content: center;
  }

  /* Make sure the third button appears below and is centered */
  .profile-buttons > button:nth-child(3),
  .profile-buttons > div:nth-child(3) {
    margin: 4px auto; /* Center the third button */
  }

  .profile-buttons button {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-buttons button svg {
    margin-bottom: 8px;
  }

  .profile-buttons button span {
    display: block;
    text-align: center;
  }

  .profile-card-container {
    width: 100%;
    margin-left: 0;
    margin-top: 80px; /* Add more space for the buttons with background */
  }

  .profile-card-container section {
    border-radius: 0 !important;
    box-shadow: none !important;
  }

  .profile-friends, .profile-gallery {
    margin-left: 0 !important;
  }

  .profile-gallery>section {
    width: 100%;
  }
}