/* In your styles.css */
.search-container {
  position: relative;
}

.reset-position {
  position: absolute !important;
  top: 150% !important; /* Position at the bottom of its parent */
  left: 0 !important;
  right: 0 !important;
  margin-top: 0 !important;
  max-height: 230px !important;
  overflow: auto;
}