.payment-status-container {
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f9fafb;
  border-radius: 12px;
  text-align: center;
  animation: fadeIn 0.5s ease-out;
}

.payment-status-subtitle {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.payment-status-loader {
  color: #3498db;
  font-size: 1rem;
  margin: 1rem 0;
  animation: pulse 1.5s infinite;
}

.payment-status-error {
  color: #e74c3c;
  background-color: #fdedec;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  animation: shake 0.5s;
}

.payment-details {
  background-color: #ffffff;
  border: 1px solid #e0e4e8;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.payment-status-text {
  color: #34495e;
  margin: 0.5rem 0;
  font-size: 1.1rem;
}

.payment-amount {
  font-weight: bold;
  color: #27ae60;
  font-size: 1.25rem;
}

.payment-btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.payment-btn:hover {
  background-color: #2980b9;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-10px); }
  20%, 40%, 60%, 80% { transform: translateX(10px); }
}