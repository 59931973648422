.app-header {
  background-color: rgb(23, 127, 191);
  box-shadow: none;
  box-sizing: border-box;
  height: 56px;
  padding: 8px 56px 8px 16px;
  position: relative;
  width: 100%;
  z-index: initial;
  display: flex;
  align-items: center;
}

.app-logo {
  height: 100%;
  cursor: pointer;
  max-width: 144px;
}

.header-right {
  position: absolute;
  right: 8px;
  top: 0px;
  padding: 8px 0px;
}

.app-header-buttons {
  display: flex;
  align-items: center;
  /* This centers the children vertically */
}

.app-header-buttons a {
  color: #fefefe;
  text-decoration: none;
  padding: 0 10px;
  margin: 0;
}

.app-header-buttons p a {
  color: black;
}

.app-header-buttons a:last-of-type {
  border-right: 1px solid hsla(0, 0%, 100%, .3);
  padding-right: 10px;
  margin-right: 10px;
}

.app-header-buttons button {
  background-color: transparent;
  border-radius: 50%;
  border: 0px;
  cursor: pointer;
  height: 40px;
  outline: 0px;
  padding: 8px;
  transition: all 0.3s ease-in-out 0s;
  flex-shrink: 0;
  width: 40px;
}

.app-header-buttons button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.search-container {
  display: flex !important;
  align-items: center;
  height: 32px;
  transition: background-color 0.4s ease;
  border-radius: 3px;
}

.search-container.active {
  background-color: rgba(0, 0, 0, 0.1);
}

.header-metrics {
  color: white;
  display: flex;
  align-items: center;
  border-right: 1px solid hsla(0, 0%, 100%, .3);
  padding-right: 10px;
  height: 19.2px;
}


.search-input {
  width: 0;
  transition: width 0.4s ease;
  padding-left: 20px;
  visibility: hidden;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  height: 100%;
  font-size: 16px;
}

.search-input:focus {
  outline: none;
  border: none;
}

.search-input.active {
  width: 200px;
  visibility: visible;
}

.search-no-result-icon {
  width: 240px;
  height: auto;
  background: rgb(254, 254, 254);
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 52px;
  z-index: 1;
  box-shadow: rgba(21, 21, 21, 0.16) 0px 3px 10px, rgba(21, 21, 21, 0.23) 0px 3px 10px;
  border-radius: 3px;
  max-height: 440px;
  overflow: auto;
  margin-right: 0px;
}

.search-result-list {
  overflow-y: auto;
  padding-left: 0px;
  margin: 0px;
  width: auto;
}

.member-status.offline {
  background-color: transparent;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.reveal-content {
  top: 40px;
  right: 25px;
  position: absolute;
  background-color: white;
  width: 200px;
  border-radius: 4px;
  box-shadow: rgba(21, 21, 21, 0.12) 0px 1px 6px, rgba(21, 21, 21, 0.12) 0px 1px 4px;

  font-size: 16px;
  font-weight: bolder;
  color: rgb(117, 117, 117);
}

.reveal-content.search-results,
.reveal-content.search-results ul {
  max-height: 440px;
  overflow-y: auto !important;
  overflow-x: hidden;
  width: 240px;
  right: 0;
}

.reveal-content h1 {
  font-weight: normal;
  margin: 0px;
  font-size: 16px;
}

.reveal-content p {
  font-size: 16px;
  padding: 8px;
  margin: 0;
  color: rgb(117, 117, 117);
  text-align: center;
}

.search-results p.title {
  font-weight: bolder;
  padding-left: 16px;
  text-align: left;
}

.reveal-content hr {
  margin: 0;
  color: rgb(196, 196, 196);
}

.header {
  padding: 8px;
  margin: 0px;
  font-size: 16px;
  font-weight: bolder;
  color: rgb(117, 117, 117);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out 0s;
}

.header-content {
  display: flex;
  align-items: center;
}

.header-image {
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: rgb(247, 247, 247);
  position: relative;
  margin-right: 8px;
}

.header-text {
  width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 32px;
}

.menu-option {
  display: flex;
  align-items: center;
  justify-content: start;
}

.menu-option a {
  display: flex;
  align-items: center;
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin-right: 0.5em;
}

.icon svg {
  height: 100%;
  width: 100%;
}

.search-close-button {
  position: absolute;
  top: 4px;
  right: 4px;
}

.responsive-search>div {
  padding: 16px;
}

.responsive-search hr {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.responsive-search>div section {
  max-width: 100% !important;
}

.responsive-search input {
  padding: 4px 4px 4px 32px !important;
}

.search-header {
  font-size: 16px;
  margin: 0 0 16px;
  color: rgb(117, 117, 117);
  font-weight: bolder;
}

.responsive-search span {
  color: rgb(117, 117, 117);
  width: 100%;
  text-align: center;
  display: block;
}

.responsive-search h2>span {
  text-align: left;
}

.responsive-search-no-result-icon {
  width: 100%;
  text-align: center;
}

.responsive-search .search-icon {
  pointer-events: none;
  transition: top 0.3s, left 0.3s;
  margin-bottom: -30px;
  margin-left: 4px;
}

.responsive-search {
  display: none;
}

.logout-dialog p {
  text-align: center;
  margin-top: 0px;
  color: rgb(33, 33, 33);
}

.logout-dialog div {
  display: flex;
  justify-content: center;
}

.ongoing-call-container {
  color: white;
  margin-top: -42px;
  margin-left: 165px;
}

.ongoing-call-info {
  display: flex;
  align-items: center;
}

.ongoing-call-info div {
  margin-right: 8px;
}

.ongoing-call-info button {
  background-color: #d32f2f !important;
}

.ongoing-call-info button:hover {
  background-color: #b71c1c !important;
}

.audio-channel-header {
  display: none;
}

.header-links span {
  color: white;
  cursor: pointer;
}

.header-links span:hover {
  text-decoration: underline;
}

@media (max-width: 900px) {
  .ongoing-call-container {
    display: none;
  }

  .audio-channel-header {
    display: block;
  }
}

@media (max-width: 824px) {
  .responsive-search {
    width: 100vw;
    height: 100svh;
    position: fixed;
    z-index: 999;
    background-color: white;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
    display: block;
  }

  .responsive-search.active {
    transform: translateY(0);
  }

  .search-input.active {
    display: none;
  }
}

@media (max-width: 768px) {
  .header-links {
    display: none;
  }
}

@media(max-width: 550px) {
  .header-metrics {
    display: none;
  }

  .app-header-buttons span {
    display: none;
  }
}