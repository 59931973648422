.group-members-list {
  height: 100%;
  overflow-y: auto;
  width: 250px;
  border-left: solid 1px rgb(196, 196, 196);
  box-sizing: border-box;
}

.group-members-list h1 {
  padding: 0 0 0 0px;
  box-sizing: border-box;
  line-height: 32px;
}

.group-members-list ul {
  overflow-y: auto !important;
}

.conversation-profile section {
  filter: none !important;
}

.call-buttons button {
  margin-top: 32px;
}

.call-text {
  padding: 12px;
}

.text-badge {
  color: white;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  padding: 2px 4px;
  border-radius: 4px;
  margin-top: 0;
  margin-right: 2px;
}

.group-members-list.narrowscreen-sidebar {
  float: right;
  height: 100dvh !important;
}

.conv-with-heading {
  color: rgb(117, 117, 117);
  font-weight: normal;
  margin-bottom: 0;
}

@media (max-width: 1100px) {
  .group-members-list.widescreen-sidebar {
    display: none !important;
  }
}
