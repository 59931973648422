.admin-banlist {
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
}

.admin-banlist-container {
  padding: 20px;
  margin: 0 auto;
  max-height: calc(100% - 88px);
  overflow-y: auto;
}

.admin-banlist-card {
  background-color: white;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.2s, box-shadow 0.2s;
}

.admin-banlist-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.admin-banlist-card-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.admin-banlist-card-content p {
  margin: 0;
  color: #555;
}

.admin-banlist-card-content b {
  color: #333;
}

.admin-banlist-card-content .user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 32px;
}

.admin-banlist-card-content .user-info p {
  margin: 0;
  font-weight: bold;
  color: #333;
}

.admin-banlist-card-content .ban-details {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

.unban-button {
  position: absolute !important;
  top: 8px;
  right: 8px;
}
