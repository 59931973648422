.auth-container {
  margin-top: max(80px, calc(50vh - 190px));
  width: 360px;
  float: right;
  position: relative;
  z-index: 1;
  padding-bottom: 0px;
}

.auth-container.auth-children {
  margin-top: max(80px, calc(50vh - 230px));
}

.auth-card {
  padding: 52px;
  background-color: white;
  color: #4D4D4D;
  box-shadow: none !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding-bottom: 30px;
  padding-top: 50px;
  min-height: 380px;
}

.auth-card.register {
  padding-bottom: 50px;
}

.auth-switch-button {
  width: 100%;
  margin-bottom: 16px;
}

.auth-form {
  display: flex;
  flex-direction: column;
}

.auth-form h1 {
  margin: 0;
}

.auth-form .link {
  margin-top: 8px;
}

.auth-form label {
  margin-top: 16px;
}

.auth-error-message {
  color: red;
  font-size: 12px !important;
  margin-bottom: 0;
}

.auth-form section {
  max-width: 100% !important
}

.auth-submit-button {
  width: 100%;
  margin-top: 16px;
  background: linear-gradient(45deg, #F08B3C 0%, #E55137 170%);
}

.auth-submit-button:hover {
  font-weight: bold !important;
  background: linear-gradient(45deg, #E55137 0%, #F08B3C 170%);
}

.login .auth-submit-button {
  margin-top: 40px;
}

.gender-radio-group {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.gender-radio-group label {
  margin: 0;
}

.username-input-group {
  position: relative;
}

.username-available {
  color: green;
  top: -20px;
  left: 0;
}

.username-unavailable {
  color: red;
  top: -20px;
  left: 0;
}

.forgot-pw {
  margin-top: 24px;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}

.login .auth-selection {
  margin-bottom: 24px;
}

.auth-selection {
  display: flex;
  justify-content: space-between;
}

.auth-selection p {
  margin: 0;
  cursor: pointer;
  font-size: 1em !important;
  font-weight: 600;
}

.auth-selection .active {
  border-bottom: 3px #177EC0 solid;
}

.auth-children {
  display: flex;
  width: 720px;
}

.auth-children .auth-card {
  width: 100%;
}

.auth-children .auth-info {
  position: relative;
  width: 100%;
  padding: 52px;
  padding-top: 20px;
  padding-left: 60px;
  margin-left: -8px;
  color: white;
  z-index: -1;
  border-radius: 0 8px 8px 0;
  overflow: hidden;
}

.auth-children .auth-info::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  background: linear-gradient(90deg, rgba(45, 97, 154, 0.9) 0%, rgba(62, 188, 247, 0.3) 100%);
  z-index: -1;
  border-radius: inherit;
}

.auth-info p {
  font-size: 1.1em;
  line-height: 1.4em;
}

@media (min-height: 1000px) {
  .auth-container {
    margin-top: 200px;
  }
}

@media (max-height: 700px) {
  .auth-container {
    margin-top: 200px;
  }
}

@media (max-width: 1020px) {
  .auth-container {
    margin-top: -100px;
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 800px) {
  .auth-children {
    max-width: 360px;
  }

  .auth-info {
    display: none;
  }
}

@media (max-width: 700px) {
  .auth-container {
    margin-top: 0px;
    padding-bottom: 0;
  }
}

@media (max-width: 450px) {
  .auth-container {
    width: auto;
  }
}

@media (max-width: 375px) {
  .auth-card {
    padding: 24px;
  }
}