.subscriptions-container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 2rem 1rem;
  margin-top: -200px;
  z-index: 10;
  left: 0;
  right: 0;
}

.subscriptions-header {
  text-align: center;
  margin-bottom: 2.5rem;
}

.subscriptions-header h1 {
  font-size: 2.25rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.subscriptions-header p {
  color: #7f8c8d;
  font-size: 1rem;
}

.subscriptions-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.subscription-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.subscription-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.subscription-header {
  background-color: #f7f9fa;
  padding: 1rem;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 8px 8px 0 0;
}

.subscription-header h2 {
  margin: 0;
  text-align: center;
  color: #2c3e50;
  font-size: 1.25rem;
}

.subscription-content {
  padding: 1.5rem;
}

.subscription-features {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1.5rem;
}

.subscription-features li {
  padding: 0.5rem 0;
  border-bottom: 1px solid #f1f3f4;
  color: #34495e;
}

.subscription-features li:last-child {
  border-bottom: none;
}

.subscription-pricing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;
}

.price-option {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price-option span {
  color: #7f8c8d;
  font-size: 0.9rem;
}

.price-option strong {
  color: #2ecc71;
  font-size: 1.25rem;
}

.subscription-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subscription-button:hover {
  background-color: #2980b9;
}

@media (max-width: 1024px) {
  .subscriptions-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .subscriptions-grid {
    grid-template-columns: 1fr;
  }
}