.timeline-body, .timeline-container {
  width: 100%;
  background-color: #f6f6f6;
}

.timeline-body {
  overflow-y: auto;
}

.timeline-content {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 0 16px;
  height: calc(100% - 185px);
}

.timeline-section {
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
}

.timeline-top {
  height: 121px;
  margin: 16px auto 0 auto;
  padding: 0 16px 0 16px;
}

.timeline-top-mobile {
  height: auto;
  margin: 16px auto 0 auto;
  padding: 0 16px 0 16px;
  display: none;
}

.timeline-card {
  margin-top: 16px;
  width: 300px;
  height: calc(100% - 359px);
}

.timeline-top a, .timeline-card a {
  height: 100%;
  display: flex;
  justify-content: center;
}

.timeline-top a img, .timeline-card a img {
  width: auto;
}

.timeline-middle-card {
  width: 352px;
  height: 278px;
  margin-bottom: 500px;
}

.timeline-friends {
  min-height: 300px;
  max-height: calc(100% - 298px);
  height: 100%;
}

.timeline-friends section {
  overflow-y: auto !important;
}

.timeline-friends li section {
  overflow-y: clip !important;
}

.timeline-container ul {
  overflow: hidden !important;
}

.timeline-conversations {
  min-height: 300px;
  max-height: calc(100% - 61px);
  height: 100%;
}

.timeline-bottom-right {
  width: 300px;
  height: 250px;
}

.timeline-side-card {
  width: 200px;   
  height: 750px;
  background-color: white;
  margin-top: 16px;
}

@media (max-height: 930px) {
  .timeline-container {
    padding-bottom: 120px;
  }
}

@media (min-width: 1280px) {
  .ad-slot {
    display: none !important;
  }
}

@media (min-width: 1100px) {
  .timeline-top {
    width: 984px;
  }
}

@media (max-height: 1024px) {
  .timeline-side-card {
    height: 500px;
  }
}

@media (max-width: 1700px) {
  .timeline-side-card {
    display: none;
  }
}

@media (max-width: 1000px) {
  .ad-slot {
    width: 728px;
    height: 90px;
  }
  
  .timeline-top-mobile {
    display: block;
    max-width: 448px;
  }
}

@media (max-width: 750px) {
  .ad-slot {
    width: 336px;
    height: 280px;
  }
}

@media (max-width: 1280px) {
  .timeline-top {
    max-width: 668px;
    height: 82px;
  }

  .timeline-card-left {
    display: none;
  }
}

@media (max-width: 963px) {
  .timeline-friends {
    display: none;
  }

  .timeline-middle-card, .timeline-top {
    max-width: 448px;
    height: 332px;
  }

  .timeline-middle-card {
    width: auto;
  }
}