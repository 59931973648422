.add-group-modal {
  max-height: 70vh;
  overflow-y: auto;
}

.add-group-modal-list {
  max-height: 30vh;
  overflow-y: auto;
}

.add-group-modal-list ul {
  overflow-y: auto !important; 
}

.addGroupModalHeaderContainer {
  width: 100%;
}

.addGroupModalHeaderContainer .step-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step-two section {
  margin-top: 10px;
  width: 100% !important; 
  max-width: 100% !important;
}

.addGroupModalHeaderTitle {
  margin: 0;
}

.addGroupModalHeaderSpan {
  font-size: 16px;
}

.no-friends {
  height: 50px;
  overflow: hidden;
  text-align: center;
  margin-top: 50px;
}