.terms-page {
  display: flex;
}

.terms-page section {
  width: 100%;
  max-width: 600px;
  padding: 0 40px;
}

.terms-page h1 {
  color: #177EC0;
  font-size: 1.5em;
}

.terms-page h2 {
  font-size: 1.17em;
}

.terms-page li {
  font-size: 1.17em;
  padding-bottom: 8px;
}

.terms-page a {
  color: #177EC0;
}

@media (max-width: 768px) {
  .terms-page {
    flex-direction: column;
    align-items: center;
  }

  .terms-page section {
    padding: 0 20px;
  }
}