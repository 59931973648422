
.footer {
  margin-top: 100px;
  width: 100%;
  min-height: 300px;
  background-color: #4D4D4D;
  color: white;
  text-align: center;
}

.footer section {
  height: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
}

.footer article {
  display: inline-block;
  min-width: 325px;
  width: 33.33%;
  padding: 20px;
  box-sizing: border-box;
  vertical-align: top;
}

.footer h3 {
  text-align: center;
  margin-bottom: 10px;
}

.footer ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.footer a,
.footer li {
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  transition: color 0.3s, transform 0.3s;
}

.footer li:hover,
.footer li:hover a,
.footer a:hover {
  color: #FF9700;
  transform: translateX(5px);
}

.footer article p a {
  display: inline-block;
  margin: 2px 0;
  padding-left: 10px;
  padding-right: 10px;
}

.footer em {
  font-size: 1.05em;
  font-weight: 600;
}

.footer article:nth-child(2) {
  max-width: 328px;
}

.footer img {
  margin-top: calc((100% - 157px) / 3);
  width: 100%;
}

.footer .copyright {
  margin-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  text-align: center;
}

@media (max-width: 1020px) {
  .footer section {
    flex-direction: column;
    align-items: center;
  }

  .footer article {
    margin-bottom: 20px;
  }

  .footer article:nth-child(2) {
    max-width: none;
  }

  .footer img {
    margin-top: 20px;
  }
}

@media (max-width: 450px) {
  .footer article {
    min-width: 100%;
  }
}