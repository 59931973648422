.button-toggle-menu {
  display: none;
}

.button-toggle-menu.profile-toggle-menu {
   position: absolute;
  top: 8px;
  left: 8px;
  background-color: rgba(21, 21, 21, 0.2); 
}

@media (max-width: 1100px) {
  .button-toggle-menu.button-toggle-right {
    display: block;
  }
}

@media (max-width: 824px) {
  .button-toggle-menu {
    display: block;
  }
}

