@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.chat-page {
  animation: fadeIn 0.3s ease-in-out;
}

.chat-body {
  min-height: calc(100dvh - 56px);
  height: calc(100dvh - 56px);
  box-sizing: border-box;
  display: flex;
  position: absolute;
  width: 100%;
  z-index: 0;
}

.loading-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100svh;
  color: #4d4d4d;
  padding-left: 5vw;
  padding-right: 5vw;
}

.loading-page img {
  width: 100%;
  max-width: 350px;
  margin-bottom: 20px;
}

.loading-page h1 {
  width: 108px;
}

.warning-dialog-text {
  color: black;
  text-align: center;
}

.adblock-dialog-text {
  color: black;
}

.call-jumpscare p {
  padding-top: 24px;
  padding-bottom: 24px;
}

.call-jumpscare-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.call-jumpscare-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.call-jumpscare-buttons button {
  margin: 0 8px;
}

@media (max-width: 400px) {
  .loading-page h1 {
    font-size: 1.5rem;
    width: 82px;
  }
}