.loggedin-profile {
  width: auto;
}

.account-details-container {
  margin-bottom: 32px;
  margin-left: 24px;
}

.account-details-title {
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 16px;
}

.account-details-list {
  margin: 0 0 16px;
}

.account-details-item {
  margin-bottom: 8px;
  color: rgb(33, 33, 33);
  font-size: 16px;
}

.account-details-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.account-details-label {
  color: rgb(117, 117, 117);
  font-weight: bolder;
  width: 160px;
  flex-shrink: 0;
  margin-bottom: 0;
  font-size: inherit;
}

.account-details-value {
  width: 100%;
  overflow: hidden;
  margin-inline-start: 0;
  padding-bottom: 8px;
}

.account-details-edit {
  margin-top: 8px;
}

.account-input {
  max-width: 400px;
}

.account-input section {
  min-height: 24px;
  max-width: 400px !important;
  height: auto !important;
  overflow-y: hidden !important;
}

.account-input header {
  overflow-y: hidden;
}

.account-input button {
  margin-top: 8px;
  margin-right: 8px;
}

.account-input textarea {
  height: 160px !important;
}

.profile-char-count {
  color: rgb(196, 196, 196);
  float: right;
  margin-top: 8px;
}

.edit-avatar-button {
  background-color: transparent;
  border-radius: 50%;
  border: 0px;
  cursor: pointer;
  height: 152px;
  width: 152px;
  padding: 8px;
  position: absolute;
  top: 124px;
  left: 26px;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.65) 100%);
}

.edit-avatar-button:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.85) 100%);
}

.edit-avatar-button svg {
  margin-top: 98px;
}

.white-page-popover {
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
}

.edit-avatar-popover {
  height: 100%;
}

.edit-avatar-popover.closed {
  display: none;
}

.edit-avatar-popover div {
  padding: 32px 24px;
  width: calc(100% -32px);
  height: calc(100% -24px);
}

.edit-avatar-popover h1 {
  color: rgb(117, 117, 117);
  margin: 0px 0px 24px;
  line-height: 24px;
  font-size: 24px;
  font-weight: bolder;
  text-align: center;
}

.edit-avatar-section {
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  height: 320px;
  width: 320px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.edit-avatar-section button {
  margin-top: 248px;
  background-color: rgba(0, 0, 0, 0.5) !important;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-avatar-section button:hover {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

/* Gallery Section Styles */
.gallery-section {
  margin-bottom: 20px;
}

.gallery-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.gallery-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.gallery-count {
  margin-left: 8px;
  color: #666;
  font-size: 14px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
}

.gallery-item {
  position: relative;
  width: 100%;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-image-btn {
  position: absolute !important;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.6) !important;
  width: 28px !important;
  height: 28px !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50% !important;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.gallery-item:hover .remove-image-btn {
  opacity: 1;
}

.add-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
  border-radius: 8px;
  border: 2px dashed #ccc;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.add-image-container:hover {
  background-color: #f0f0f0;
}

.add-image-container span {
  margin-top: 8px;
  font-size: 12px;
  color: #666;
}

.gallery-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  color: #666;
}

@media (max-width: 768px) {
  .loggedin-profile {
    margin-top: 48px;
  }
}
