.navigation {
  top: 0;
  background-color: white;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 999;
  width: 100%;
  box-shadow: rgba(21, 21, 21, .12) 0 1px 6px, rgba(21, 21, 21, .12) 0 1px 4px;
}

.navigation .homepage-content,
.navigation .container-content {
  height: 60px;
}

.navigation img {
  width: 200px;
  height: 100%;
  align-items: center;
}

.navigation ul,
.hamburger {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 64px;
  float: right;
  height: 100%;
  align-items: center;
}


.hamburger {
  display: none;
  transform: scaleX(-1);
}

.navigation li,
.navigation a {
  font-size: 16px;
  font-weight: bold;
  color: #4D4D4D;
  transition: color 0.3s ease;
  cursor: pointer;
}

.navigation li:hover,
.navigation a:hover {
  color: #02a8f4;
}

.navigation a {
  text-decoration: none;
  color: #4D4D4D;
}

.nav-sidebar {
  z-index: 999;
}

.nav-sidebar .sidebar-content {
  padding: 20px;
}

.nav-sidebar img {
  height: 48px;
  width: auto;
}

.nav-sidebar section p {
  font-weight: 600 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.navigation ul a.active {
  color: #FF9700;
}

.nav-sidebar .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1200px) {
  .navigation li {
    display: none;
  }

  .hamburger {
    display: flex;
  }
}


@media (max-width: 450px) {
  .navigation img {
    max-width: 50vw;
  }
}