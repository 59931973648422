.layout-height {
  min-height: calc(100dvh - 790px);
}

.hero-text a {
  color: white;
  text-decoration: none;
}

.hero-text a:hover {
  text-decoration: underline;
}

.hero-text svg {
  transform: scale(1.5);
}