.chat-component {
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 56px);
  width: calc(100vw - 500px);
}

.chat-component h1 {
  line-height: 18px !important;
}

.message-list {
  flex: 1;
  overflow-y: auto;
  background-color: rgb(246, 246, 246) !important;
  background-image: url(./../../assets/chat-background.jpg) !important;
  background-size: 300px !important;
  padding-bottom: 86px;
}

.message-list button {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 16px;
}

.message-list.emoji-padding {
  padding-bottom: 319px;
  margin-bottom: -255px;
}

.message-list.command-padding {
  padding-bottom: 269px;
  margin-bottom: -205px;
}

.message-list.reply-padding {
  padding-bottom: 145px;
  margin-bottom: -81px;
}

.message-list article {
  height: 100% !important;
}

.message {
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

.message-user {
  font-weight: bold;
}

.message-input {
  display: flex;
  margin-top: -64px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 32px);
}

.message-input>div {
  width: 100%;
  display: flex;
}

.message-input input {
  flex: 1;
  border: none;
  margin: none !important;
}

.message-input button {
  padding: 10px;
}

.message-text img {
  max-width: 30%;
  max-height: 20%;
}

.poll-container {
  padding: 8px;
}

.poll-question {
  margin-top: 0;
}

.poll-options {
  display: flex;
  flex-direction: column;
}

.poll-option {
  display: flex;
  align-items: center;
  list-style-type: none;
  max-width: 500px;
  min-width: 200px;
  margin-bottom: 8px;
  cursor: pointer;
}

.poll-option svg.checked {
  background-color: white;
  border-radius: 50%;
}

.poll-label {
  margin-left: 8px;
  cursor: pointer;
}

.chatbot-msg {
  opacity: 0.5;
}

.image-message img {
  max-height: 200px !important;
  max-width: 100% !important;
  height: auto !important;
  cursor: pointer;
}

.chat-top-rectangle {
  width: 900px;
  height: 125px;
  /* background-color: white; */
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  position: absolute;
  left: calc(50vw - 700px);
  /* transform: translateX(-50%); */
  margin-top: 20px;
  z-index: 1;
}

/* vvv Modal styles vvv */

.poll-divider {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.poll-option-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.poll-remove-button {
  margin-left: 8px;
  align-self: flex-end;
}

.poll-add-option-container {
  width: 100%;
  margin-top: 16px;
  height: 24px;
}

.poll-add-option-button {
  float: right;
}

/* Gif picker styles */
.GifPickerReact {
  margin: -452px 0 2px auto;
  border-radius: 4px !important;
  border: none !important;
  box-shadow: rgba(21, 21, 21, 0.12) 0px 1px 6px, rgba(21, 21, 21, 0.12) 0px 1px 4px;
  max-width: 90vw;
  height: 100%;
}

.GifPickerReact input {
  border-radius: 4px !important;
  height: 32px !important;
}

.GifPickerReact input:focus {
  border: none !important;
}

.gpr-body {
  margin-bottom: 16px;
}

/* Header */
.header-title img {
  height: 32px;
}

.header-title a {
  display: block;
  width: 100%;
  text-decoration: none;
  color: inherit;
}

.rotation-content {
  position: relative;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 1;
  transform: translateY(0);
}

.rotation-content.fade-in {
  opacity: 1;
  transform: translateY(0);
  /* Moves to normal position */
}

.rotation-content.fade-out {
  opacity: 0;
  transform: translateY(-50%);
  /* Moves up and fades out */
}

/* Styling for the message-to-reply component */
.message-to-reply {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}

.reply-container {
  width: 100%;
  padding: 10px;

}

.message-to-reply-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  width: 100%;
}

.message-to-reply-header small {
  margin: 0;
  font-weight: bold;
  color: rgb(117, 117, 117);
}

.message-to-reply-header button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  vertical-align: top;
  padding: 0;
}

.message-to-reply-header button:hover {
  text-decoration: underline;
}

.message-to-reply-body {
  padding-top: 5px;
  color: #333;
  display: block;
  width: 100%;
  clear: both;
}

/* Styling for the reply-message component */
.reply-message {
  display: block;
  background-color: rgba(0, 0, 0, 0.07);
  padding: 10px;
  border-radius: 4px;
  margin: 5px 0;
  border-left: solid 3px white;
  color: rgb(117, 117, 117)
}

.reply-username {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.reply-text {
  display: block;
}

.gif-picker input {
  font-size: 16px;
}

.sendmessageinput-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background-color: white;
  box-shadow: rgba(21, 21, 21, 0.12) 0px 1px 6px, rgba(21, 21, 21, 0.12) 0px 1px 4px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.sendmessageinput-input {
  flex: 1 1 0%;
  appearance: none;
  color: rgb(33, 33, 33);
  font-size: 1rem;
  line-height: 16px;
  font-weight: inherit;
  font-family: inherit;
  outline: none;
  padding: 15px 0 15px;
  resize: none;
  overflow: auto;
  min-width: 0px;
}

.sendmessageinput-input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.sendmessageinput-buttons {
  display: flex;
  gap: 4px;
}

.typing-indicator {
  position: absolute;
  margin-top: -24px;
}

.chat-top-rectangle-mobile {
  display: none;
}

.adsense-container {
  position: absolute;
}

@media (max-width: 1440px) {
  .chat-top-rectangle {
    width: 560px;
    left: calc(50vw - 530px);
  }
}

@media (max-width: 1100px) {
  .chat-component {
    width: calc(100% - 32px);
  }

  .chat-top-rectangle {
    left: calc(50vw - 400px);
    height: 80px;
  }

  .chat-component {
    width: 100%;
  }
}

@media (max-width: 825px) {
  .chat-top-rectangle {
    left: calc(50vw - 280px);
  }
}

@media (max-width: 600px) {
  .chat-top-rectangle {
    width: 400px;
  }
}

@media (max-width: 434px) {
  .message-list img {
    max-width: 100%;
    height: auto !important;
  }

  .chat-top-rectangle {
    display: none;
  }

  .chat-top-rectangle-mobile {
    display: block !important;
  }
}