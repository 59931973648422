.settings-page {
  display: flex;
}

.settings-page .widescreen-sidebar {
  height: calc(100dvh - 56px);
}

.settings-body {
  width: 100%;
}

.settings-body h2 {
  font-size: 22px;
  color: rgb(117, 117, 117);
  font-weight: bolder;
  margin: 0px 0px 16px;
}

.settings-content {
  padding: 16px;
}

.settings-content ul {
  overflow-y: auto !important;
  max-width: 320px;
}

.settings-page .sidebar li {
  height: 40px !important;
}

.theme-selection.active {
  background-color: #ddd;
  font-weight: bold;
}

.theme-selection button {
  height: 128px;
  width: 128px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  transition: all 0.3s ease;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
  cursor: pointer;
}

.theme-selection button:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.theme-selection button.active {
  font-weight: bold;
}

.theme-selection .blue-button {
  background-color: #177FBF;
}

.theme-selection .orange-button {
  background-color: #f2922c;
}

.theme-selection .green-button {
  background-color: #22ac55;
}

.theme-selection .yellow-button {
  background-color: #fbc02d;
}

.theme-selection .purple-button {
  background-color: #5d3c85;
}

.theme-selection .red-button {
  background-color: #d32f2f;
}

.theme-selection .pink-button {
  background-color: #e91e63;
}

.theme-selection .black-button {
  background-color: #212121;
}

.account-section {
  margin-top: 12.5px;
  margin-bottom: 10px;
  padding-left: 8px;
  max-width: 320px;
}

.account-section .change-password {
  display: flex;
  align-items: flex-end;
}

.account-section.delete-account {
  margin-top: 32px;
}

.account-section button {
  margin-top: 16px;
}

.account-label {
  color: rgb(117, 117, 117);
  padding-bottom: 8px;
  font-weight: bolder;
  display: block;
}

.account-label-text {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  line-height: inherit;
}

.account-username {
  font-size: 16px;
  color: rgb(33, 33, 33);
  margin: 0px;
}

.error-message {
  color: red;
}

.setting-select {
  margin-bottom: 8px;
}