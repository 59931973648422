.blog-card {
  max-width: 320px;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-card.timeline {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 370px;
  border-radius: 4px;
  box-shadow: none;
  text-decoration: none;
}

.blog-card.timeline img {
  width: 100%;
}

.blog-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.blog-card:hover p, .blog-card:hover h3, .blog-card:hover small {
  color: #177EC0;
}

.blog-card img {
  width: 100%;
  max-height: 200px;
  transition: transform 0.3s ease;
}

.blog-card:hover img {
  transform: translateY(-8px) scale(1.1);
}

.blog-card h3 {
  margin-top: 0;
}

.blog-card-content {
  padding: 16px;
  color: rgba(77, 77, 77, 0.8);
}

.blog-picker {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
}

.blog-picker a {
  text-decoration: none;
  color: inherit;
}

.blog-article {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.blog-section h3 {
  margin-bottom: 5px;
}

.blog-section p {
  margin-top: 0;
}

@media (max-width: 1250px) {
  .blog-card {
    max-width: 39vw;
  }
}

@media (max-width: 1020px) {
  .blog-card {
    max-width: 43.6vw;
  }
}

@media (max-width: 715px) {
  .blog-card {
    max-width: 100%;
  }
}