.sidebar {
  width: 249px;
  min-width: 249px;
  border-right: 1px solid rgb(196, 196, 196);
}

.channel-list {
  max-height: calc(100dvh - 104px);
  overflow-y: auto;
}

.sidebar ul {
  overflow: hidden !important;
}

.active-channels li h1,
.active-channels li h2 {
  color: white !important;
}

.tab-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab-icon-container {
  width: 24px;
  height: 24px;
}

.tab-label {
  color: rgb(117, 117, 117);
  text-transform: capitalize;
  font-size: 12px;
  font-weight: bolder;
}

.tab-label-inner {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  line-height: inherit;
}

.badge-unread {
  background-color: rgb(254, 254, 254);
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: rgba(21, 21, 21, 0.12) 0px 1px 6px, rgba(21, 21, 21, 0.12) 0px 1px 4px;
  color: rgb(23, 127, 191);
  display: block;
  font-size: 12px;
  font-weight: inherit;
  height: 20px;
  line-height: 14px;
  padding: 3px;
  text-align: center;
  min-width: 20px;
  transition: background 0.3s ease-in-out 0s;
}

.conversationlist-container {
  display: flex;
  justify-content: space-between;
}

.conversationlist-date {
  color: rgb(196, 196, 196);
  font-size: 12px;
  flex-shrink: 0;
  margin-left: 4px;
}

.narrowscreen-sidebar {
  background-color: white;
  height: 100svh;
}

.narrowscreen-sidebar .channel-list {
  max-height: calc(100dvh - 48px);
  overflow-y: auto;
  background-color: white;
}

.ads-section {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ads-section.slide-in {
  animation: slide-in 0.5s forwards;
}

.ads-section.slide-out {
  animation: slide-out 0.5s forwards;
}

.conversationlist-header {
  display: flex;
  justify-content: space-between;
}

.conversationlist-header div,
.conversationlist-header button {
  height: 18px;
  z-index: 1;
  margin-top: -3px;
}

.conversationlist-header .send-button {
  height: 18px !important;
  z-index: 1;
  margin-top: -5px;
}

.conversationlist-header .send-button:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}

.conversationlistitem {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px 8px;
  transition: background-color 0.3s;
  height: 52px;
  max-height: 52px;
}

.conversationlistitem:hover {
  background-color: rgba(0, 0, 0, 0.05)
}

.conversationlistitem-avatar {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.conversationlistitem-blocked {
  margin-left: 8px;
}

.conversationlistitem-text {
  flex: 1;
}

.conversationlistitem-primary-text {
  margin: 0;
  font-size: 1rem;
  font-weight: 300;
}

.conversationlistitem-secondary-text {
  margin: 0;
  font-size: 0.875rem;
  color: rgb(117, 117, 117);
  font-weight: 300;
}

.conversationlistitem-right-button {
  display: none;
}

.conversationlistitem-right-button.mobile {
  display: block;
}

.conversationlistitem:hover .conversationlistitem-right-button {
  display: block;
}

.conversationlistitem-date {
  font-size: 0.75rem;
  color: rgb(196, 196, 196);
  min-width: 57px;
  text-align: right;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: align-items 0.3s ease, padding-bottom 0.3s ease;
}

.conversationlistitem:hover .conversationlistitem-date {
  display: none;
}

.conversationlistitem-avatar .badge-unread {
  z-index: 1;
  margin-left: -16px;
  margin-right: -2px;
  margin-top: -24px;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}


@media (max-width: 824px) {
  .widescreen-sidebar {
    display: none !important;
  }
}